
import { defineComponent } from "vue";
import Item from "./Item.vue";

export default defineComponent({
  name: "Navigation Bar",

  components: { Item },

  computed: {
    show() {
      return !this.$route.meta.blank;
    },
  },
});
