
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation Bar Item",

  props: {
    to: { required: true },
    icon: { type: String, required: true },
    iconRound: { type: Boolean, default: false },
    iconOutlined: { type: Boolean, default: false },
    label: { type: String, required: true },
    replace: { type: Boolean, default: false },
  },

  computed: {
    iconClass() {
      return this.iconRound
        ? "material-icons-round"
        : this.iconOutlined
        ? "material-icons-outlined"
        : "material-icons";
    },
  },
});
