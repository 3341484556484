import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37a1a0ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Item = _resolveComponent("Item")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Item, {
          to: { name: 'App' },
          icon: "",
          replace: "",
          "icon-round": "",
          label: "Главная"
        }),
        _createVNode(_component_Item, {
          to: { name: 'History' },
          replace: "",
          "icon-round": "",
          icon: "",
          label: "История"
        }),
        _createVNode(_component_Item, {
          to: { name: 'Profile' },
          replace: "",
          "icon-round": "",
          icon: "",
          label: "Профиль"
        })
      ], 512), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }))
}